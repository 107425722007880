import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { OopsComponent } from './oops/oops.component';
import { HeaderWithMenuComponent } from './components/header-with-menu/header-with-menu';
import { FooterCommonComponent } from './components/footer-common/footer-common';
import { GundrySecureCartComponent } from './gundry-secure-cart/gundry-secure-cart.component';
import { GundrySecureCartBTComponent } from './gundry-secure-cart-bt/gundry-secure-cart-bt.component';
import { HttpInterceptorProvider } from './src/services/http-interceptor/http-interceptor.component';
import { CookiesProvider } from './providers/cookies';
import { TouchcrApiContentProvider } from './providers/touchcr-api-content';
import { TouchcrApiAccountProvider } from './providers/touchcr-api-account';
import { TouchcrApiShippingProvider } from './providers/TouchcrApiShippingProvider';
import { TouchcrApiRemoteServicesProvider } from './providers/touchcr-api-remoteservices';
import { CalculateProvider} from './providers/calculate/calculate';
import { Storage } from '@ionic/storage-angular';
import { StateProvider } from './providers/state';
import { StateExtenderProvider } from './providers/state-extender';
import { TouchcrApiAffiliateComponent } from './src/services/touchcr-api-affiliate/touchcr-api-affiliate.component';
import { FunnelSettingsProvider } from './providers/funnel-settings';
import { FunnelSettingsExtenderProvider } from './providers/funnel-settings-extender';
import { TouchcrApiCouponProvider } from './src/services/touchcr-api-coupon/touchcr-api-coupon.component';
import { TouchcrApiPaymentProvider } from './src/services/touchcr-api-payment/touchcr-api-payment';
import { EventStreamerProvider } from './providers/event-stream';
import { LoggingProvider } from './providers/logging';
import { OrderProvider } from './providers/order/order';
import { OrderProviderExtender } from './providers/order/order-extender';
import { TouchcrApiOrderformProvider } from './providers/touchcr-api-orderform/touchcr-api-orderform';
import { TouchcrApiOrderProvider } from './providers/touchcr-api-order/touchcr-api-order';
import { TouchcrApiOrderExtenderProvider } from './providers/touchcr-api-order/touchcr-api-order-extender';
import { PaypalPaymentProvider } from './providers/paypal/paypal';
import { PaypalPaymentExtenderProvider } from './providers/paypal/paypal-extender';
import { AutocompleteProvider } from './providers/autocomplete/autocomplete';
import { CreditCardProvider } from './providers/credit-card/credit-card';
import { CheckoutCommon } from './pages/_brand-pages-base/base-controllers/checkout/checkout-common';
import { PaymentFormComponent } from 'src/components/payment-form/payment-form';
import { PaymentFormComponentBase } from 'src/components/payment-form/payment-form-base';
import { ShippingFormComponent } from 'src/components/shipping-form/shipping-form';
import { CurrencyConversionProvider } from './providers/currency-conversion/currency-conversion';
import { EmptyBodyInterceptorProvider } from './src/services/empty-body-interceptor/empty-body-interceptor';
import { CheckoutLanguageProvider } from './providers/checkout-language/checkout-language';
import { FaqProvider } from './providers/faq/faq';
import { AccountFormComponent } from 'src/components/account-form/account-form';
import { AutocompleteComponent } from 'src/components/autocomplete/autocomplete';
import { UpsellOfferSpinnerComponent } from 'src/components/upsell-offer-spinner/upsell-offer-spinner';
import { UpsellGuaranteeComponent } from 'src/components/upsell-guarantee/upsell-guarantee';
import { UpsellFooterComponent } from 'src/components/upsell-footer/upsell-footer';
import { UpsellButtonsComponent } from 'src/components/upsell-buttons/upsell-buttons';
import { UpsellProductBoxComponent } from 'src/components/upsell-product-box/upsell-product-box';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { UpsellBase } from 'src/pages/upsell/upsell-base';
import { UpsellCommon } from './upsell/upsell-common';
import { RouterModule, Router } from '@angular/router';
import { WhatIsThisPage } from 'src/pages/what-is-this/what-is-this';
import { SafeOrderPage } from 'src/pages/safe-order/safe-order';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { UpsellStickyBar } from 'src/components/upsell-sticky-bar/upsell-sticky-bar';
import { SimpleVideoPlayerComponent } from 'src/components/simple-video-player/simple-video-player';
import { VideoPlayerComponent } from 'src/components/video-player/video-player';
import { UpsellStepsComponent } from 'src/components/upsell-steps/upsell-steps';
import { UpsellSectionComponent } from 'src/components/upsell-section/upsell-section';
import { UpsellProductBoxFullComponent } from 'src/components/upsell-product-box-full/upsell-product-box-full';
import { UpsellWaitComponent } from 'src/components/upsell-wait/upsell-wait';
import { DestinationComponent } from './destination/destination.component';
import { UpsellDynamic } from './upsell/upsell-dynamic';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { GdprComponent } from './gdpr/gdpr.component';
import { CcpaComponent } from './ccpa/ccpa.component';
import { TermsComponent } from './terms/terms.component';
import { AudCurrencyPipe } from './aud-currency.pipe';
import { CadCurrencyPipe } from './cad-currency.pipe';
import { SharedModule } from './shared/shared.module';

@NgModule({ declarations: [
        AppComponent,
        OopsComponent,
        HeaderWithMenuComponent,
        FooterCommonComponent,
        GundrySecureCartComponent,
        GundrySecureCartBTComponent,
        CheckoutCommon,
        PaymentFormComponent,
        PaymentFormComponentBase,
        ShippingFormComponent,
        AccountFormComponent,
        AutocompleteComponent,
        UpsellOfferSpinnerComponent,
        UpsellGuaranteeComponent,
        UpsellFooterComponent,
        UpsellButtonsComponent,
        UpsellProductBoxComponent,
        UpsellProductBoxFullComponent,
        UpsellBase,
        UpsellCommon,
        WhatIsThisPage,
        SafeOrderPage,
        UpsellStickyBar,
        SimpleVideoPlayerComponent,
        VideoPlayerComponent,
        UpsellStepsComponent,
        UpsellSectionComponent,
        UpsellWaitComponent,
        DestinationComponent,
        UpsellDynamic,
        PrivacyPolicyComponent,
        GdprComponent,
        CcpaComponent,
        TermsComponent,
        AudCurrencyPipe,
        CadCurrencyPipe,
    ],
    bootstrap: [AppComponent],
    exports: [
        HeaderWithMenuComponent,
        UpsellProductBoxComponent,
        UpsellProductBoxFullComponent,
        UpsellButtonsComponent,
        UpsellGuaranteeComponent,
        UpsellStickyBar,
        SimpleVideoPlayerComponent,
        VideoPlayerComponent,
        UpsellStepsComponent,
        UpsellSectionComponent,
        UpsellFooterComponent,
        UpsellWaitComponent,
        DestinationComponent,
        UpsellDynamic,
    ],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA], 
    imports: [BrowserModule,
        AppRoutingModule,
        FormsModule,
        CommonModule,
        FormsModule,
        RouterModule,
        BrowserAnimationsModule,
        SharedModule], 
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorProvider,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: EmptyBodyInterceptorProvider,
            multi: true,
        },
        CookiesProvider,
        TouchcrApiContentProvider,
        Storage,
        CalculateProvider,
        StateProvider,
        StateExtenderProvider,
        TouchcrApiRemoteServicesProvider,
        TouchcrApiShippingProvider,
        TouchcrApiAccountProvider,
        Storage,
        TouchcrApiAffiliateComponent,
        FunnelSettingsProvider,
        FunnelSettingsExtenderProvider,
        TouchcrApiCouponProvider,
        TouchcrApiPaymentProvider,
        EventStreamerProvider,
        LoggingProvider,
        OrderProvider,
        OrderProviderExtender,
        TouchcrApiOrderformProvider,
        TouchcrApiOrderProvider,
        TouchcrApiOrderExtenderProvider,
        PaypalPaymentProvider,
        PaypalPaymentExtenderProvider,
        AutocompleteProvider,
        CreditCardProvider,
        CurrencyConversionProvider,
        CheckoutLanguageProvider,
        FaqProvider,
        UpsellFooterComponent,
        UpsellProductBoxComponent,
        UpsellProductBoxFullComponent,
        UpsellBase,
        UpsellStickyBar,
        SimpleVideoPlayerComponent,
        VideoPlayerComponent,
        UpsellStepsComponent,
        UpsellSectionComponent,
        UpsellWaitComponent,
        DestinationComponent,
        UpsellDynamic,
        provideHttpClient(withInterceptorsFromDi()),
        AudCurrencyPipe,
        CadCurrencyPipe,
        CurrencyPipe,
        SharedModule
    ] })
export class AppModule {
  constructor(public storage: Storage, public router: Router) {
    this.storage = new Storage({
      name: "__ghdb",
    });
    this.storage.create();

    console.log("[GH] configured routes: ", this.router.config);
    let links = [];
    for (var prop in this.router.config) {
      //console.log("Key/Value:" + prop, this.router.config[prop]);
      links.push({
        segment: this.router.config[prop].path,
        name: this.router.config[prop].path,
      });
    }

    // for backward compatibility, we keep this array of segments??? TBD TODO:
    deepLinkConfig = {
      links: links,
    };
  }
}
export let deepLinkConfig: { links: any[]; };

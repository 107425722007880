<form #accountForm="ngForm" class="account_form">

  <div class="form_item_wrapper">
    <label for="firstName" class="form_item_label">{{ getLabel('firstName') }}</label>
    <input
      data-qa="bill-fname"
      id="firstName"
      name="firstName"
      #firstName="ngModel"
      [(ngModel)]="guest.firstName"
      type="text"
      class="item_input"
      required
      maxlength="32"
      pattern="{{ textPattern }}"
      (change)="remember_AccountInformation()"
    >
    <div class="form_item_image">
      <img *ngIf="submittedAccountForm && firstName.invalid" src="{{ errorImage }}" alt="">
    </div>
  </div>

  <div class="form_item_wrapper">
    <label for="lastName" class="form_item_label">{{ getLabel('lastName') }}</label>
    <input
      data-qa="bill-lname"
      id="lastName"
      name="lastName"
      #lastName="ngModel"
      [(ngModel)]="guest.lastName"
      type="text"
      class="item_input"
      required
      maxlength="32"
      pattern="{{ textPattern }}"
      (change)="remember_AccountInformation()"
    >
    <div class="form_item_image">
      <img *ngIf="submittedAccountForm && lastName.invalid" src="{{ errorImage }}" alt="">
    </div>
  </div>

  <div class="form_item_wrapper">
    <label for="phone" class="form_item_label not_required">{{ getLabel('phone') }}</label>
    <input
      data-qa="phone"
      id="phone"
      name="phone"
      #phone="ngModel"
      [(ngModel)]="guest.phone"
      type="tel"
      class="item_input"
      pattern="{{ phonePattern }}"
      maxlength="18"
      (change)="remember_AccountInformation()"
    >
    <div class="form_item_image">
      <img *ngIf="submittedAccountForm && phone.invalid" src="{{ errorImage }}" alt="">
    </div>
  </div>

  <div class="form_item_wrapper">
    <label for="email" class="form_item_label">{{ getLabel('email') }}</label>

    <input
      data-qa="email"
      id="email"
      name="email"
      #email="ngModel"
      [(ngModel)]="guest.email"
      type="email"
      class="item_input"
      required
      pattern="{{ emailPattern }}"
      (change)="ready_ToCreateLead(); remember_AccountInformation()"
    >
    <div class="form_item_image">
      <img *ngIf="
        submittedAccountForm &&
        email.errors &&
        (
          email.errors.required ||
          email.errors.invalidValidEmail
        )
      " src="{{ errorImage }}" alt="">
    </div>
  </div>

  <div class="form_item_wrapper">
    <label for="confirmEmail" class="form_item_label">{{ getLabel('confirmEmail') }}</label>

    <input
      data-qa="email-conf"
      id="confirmEmail"
      name="confirmEmail"
      #emailConfirm="ngModel"
      [(ngModel)]="emailToConfirm"
      type="email"
      class="item_input"
      required
      pattern="{{ emailPattern }}"
      (change)="emitConfirmEmailToCheckout(); ready_ToCreateLead()"
    >
    <div class="form_item_image">
      <img *ngIf="
        submittedAccountForm &&
        emailConfirm.invalid &&
        emailConfirm.errors &&
        (
          emailConfirm.errors.required ||
          emailConfirm.errors.invalidValidEmail ||
          !(
            email.errors &&
            email.errors.required &&
            emailConfirm.errors.invalidConfirmEmails
          )
        )
      " src="{{ errorImage }}" alt="">
    </div>
  </div>

  <div class="form_item_wrapper">
    <label for="street" class="form_item_label">{{ getLabel('address') }}</label>

    <input
      data-qa="bill-address1"
      id="street"
      name="street"
      #street="ngModel"
      [(ngModel)]="guest.street"
      type="text"
      class="item_input"
      required
      maxlength="150"
      pattern="{{ addressPattern }}"
      (change)="validate_AddressAndCalculateTaxes('billing'); 
      remember_AccountInformation()"
    >
    <div class="form_item_image">
      <img *ngIf="submittedAccountForm && street.invalid" src="{{ errorImage }}" alt="">
    </div>
  </div>

  <div class="form_item_wrapper">
    <label for="additionalBillingAddressLine" class="form_item_label not_required">{{ getLabel('addressConfirm') }}</label>
    <input
      data-qa="bill-address2"
      id="additionalBillingAddressLine"
      name="additionalBillingAddressLine"
      #additionalBillingAddressLine="ngModel"
      [(ngModel)]="guest.additionalBillingAddressLine"
      type="text"
      class="item_input"
      maxlength="150"
      (change)="remember_AccountInformation()"
    >
    <div class="form_item_image"></div>
  </div>

  <div class="form_item_wrapper">
    <label for="city" class="form_item_label">{{ getLabel('city') }}</label>

    <input
      data-qa="bill-city"
      id="city"
      name="city"
      #city="ngModel"
      [(ngModel)]="guest.city"
      type="text"
      class="item_input"
      required
      pattern="{{ textPattern }}"
      (change)="validate_AddressAndCalculateTaxes('billing'); remember_AccountInformation()"
    >
    <div class="form_item_image">
      <img *ngIf="submittedAccountForm && city.invalid" src="{{ errorImage }}" alt="">
    </div>
  </div>

  <div class="form_item_wrapper">
    <label for="zipPostalCode" class="form_item_label">{{ getLabel('zip') }}</label>

    <input
    data-qa="bill-zip"
      id="zipPostalCode"
      name="zipPostalCode"
      #zipPostalCode="ngModel"
      [(ngModel)]="guest.zipPostalCode"
      type="{{ country.value == 'United States' ? 'tel' : 'text' }}"
      class="item_input"
      required
      pattern="{{ mixedPattern }}"
      (change)="validate_AddressAndCalculateTaxes('billing'); remember_AccountInformation()"
    >
    <div class="form_item_image">
      <img *ngIf="submittedAccountForm && zipPostalCode.invalid" src="{{ errorImage }}" alt="">
    </div>
  </div>

  <div class="form_item_wrapper">
    <label for="country" class="form_item_label select">{{ getLabel('country') }}</label>
    <select
    data-qa="bill-country"
    [id]="isFacebookApp() ? '' : 'country'"
    [name]="isFacebookApp() ? 'country' : 'country'"
      #country="ngModel"
      [(ngModel)]="guest.country"
      class="item_select"
      required
      (change)="validate_AddressAndCalculateTaxes('billing'); remember_AccountInformation()"
    >
    <ng-container *ngFor="let item of countriesList">
      <option *ngIf="euSpecified || (!euSpecified && item.label !== 'Spain' && item.label !== 'France' && item.label !== 'Germany')" 
              value="{{ item.label }}" 
              id="Country-{{ item.label }}" [innerHTML]="getLabel(item.label) ? getLabel(item.label) : item.label">
      </option>
    </ng-container>
    </select>
    <div class="form_item_image">
      <img *ngIf="submittedAccountForm && country.value === ''" src="{{ errorImage }}" alt="">
    </div>
  </div>

  <input *ngIf="isFacebookApp()" id="facebookstate"  style="position:absolute; left:-1000px;"  >
  <input *ngIf="isFacebookApp()" id="FBStateCode" (change)="facebookAppStateChangeHandler()"  style="position:absolute; left:-1000px;"  >  
  <input *ngIf="isFacebookApp()" id="FBCountryCode"   style="position:absolute; left:-1000px;"  >
  <input *ngIf="isFacebookApp()" id="FBAddressLine2"   style="position:absolute; left:-1000px;" >
  
  <div class="form_item_wrapper" [ngClass]="displayStateField() ? 'form_item_wrapper' : 'hidden'">
    <label for="state" class="form_item_label select">{{ getLabel('state') }}</label>
    <div class="form_item_image">
      <img *ngIf="submittedAccountForm && state.value === '' && statesList && statesList.length > 0" src="{{ errorImage }}" alt="">
    </div>
    <select
      data-qa="bill-state"
      [id]="isFacebookApp() ? '' : 'state'"
      [name]="isFacebookApp() ? 'state' : 'state'"
      #state="ngModel"
      [(ngModel)]="guest.state"
      class="item_select"
      (change)="validate_AddressAndCalculateTaxes('billing'); remember_AccountInformation()"
    >
      <option value="" disabled>{{ getLabel('choose a state') }}</option>
      <option *ngFor="let item of statesList" value="{{ item.label }}">{{ item.label }}</option>
    </select>
  </div>

</form>
